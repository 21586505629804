<template>


  <b-card-actions ref="list" title="Employer Declaration" action-refresh @refresh="refresh">
    <div class="demo-spacing-0">
      <b-alert
          show
          variant="primary"
      >
        <div class="alert-body">
          <span
              class="ml-25">{{ $t("Select the employees you want to include in the declaration, or don't select any to print an empty declaration to be filled manually") }}</span>
          <spacer height="10"/>
          <feather-icon
              class="mr-25"
              icon="CheckIcon"
          />
          <span class="ml-25">{{ selected_rows_label }}</span>
          <span class="ml-25"> <b-button
              variant="primary"
              class="button-inline-spacing-2"
              @click="print_declaration()"
              :disabled="editDisabled"
          >
            <span class="text-nowrap"> <feather-icon
                icon="PrinterIcon"
                class="mr-50"
            />{{ print_declaration_label }}</span>
          </b-button></span>
        </div>
      </b-alert>
    </div>
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >


        </b-col>

        <!-- Search -->
        <b-col
            cols="12"
            md="4"
        >

          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('message.SearchPlaceholder')"
              type="text"
              class="d-inline-block"
          />
        </b-col>
      </b-row>

    </div>


    <!-- alert -->
    <!--b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
      </div>
    </b-alert-->

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">


    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        ref="table"
        @on-selected-rows-change="selectionChanged"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >

      <!-- Slot: Table Column -->
      <template
          slot="table-column"
          slot-scope="props"
      >

        <span>
           {{ $t(props.column.label) }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
          slot="table-row"
          slot-scope="props"
      >


        <!-- Column: First Name -->
        <span
            v-if="props.column.field === 'first_name'"
            class="text-nowrap v-align-middle"
        >
          <span>{{ props.row.first_name }}</span>
        </span>

        <!-- Column: Last Name -->
        <span
            v-else-if="props.column.field === 'last_name'"
            class="text-nowrap align-middle"
        >
                    <span>{{ props.row.last_name }}</span>

        </span>

        <!-- Column: Employee Id -->
        <span
            v-else-if="props.column.field === 'role'"
            class="text-nowrap align-middle"
        >
                    <span>{{ props.row.employee_role ? props.row.employee_role.name : "" }}</span>

        </span>
        <!-- Column: Employee Id -->
        <span
            v-else-if="props.column.field === 'department'"
            class="text-nowrap align-middle"
        >
                    <span>{{ props.row.employee_department ? props.row.employee_department.name : "" }}</span>

        </span>

        <!-- Column: Assignments -->
        <span
            v-else-if="props.column.field === 'assignments'"
            class="text-nowrap align-middle"
        >
                    <span>{{ props.row.employee_assignments }}</span>

        </span>


        <span v-else class="text-nowrap align-middle">


        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >

        <div class="d-flex justify-content-between flex-wrap">


          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
          </div>
          <div>

            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>

            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-actions>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BCardActions} from "@core/components/b-card-actions";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BAlert,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import UserService from "@/basic/userService";
import EmployeeService from "@/basic/employeeService";
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ability from "@/libs/acl/ability";
import {environment} from "@/auth/environment";

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 15,
      codeI18n,
      dir: false,
      editDisabled: !ability.can("manage", "Basic HR"),
      columns: [

        {
          label: 'First Name',
          field: 'first_name',
        },
        {
          label: 'Last Name',
          field: 'last_name',
        },

        {
          label: 'Department',
          field: 'department',
        },
        {
          label: 'Role',
          field: 'role',
        },
        {
          label: 'Assignments',
          field: 'assignments',
        },


      ],
      excel_columns: [
        {
          label: i18n.t('First Name'),
          field: 'first_name',
        },
        {
          label: i18n.t('Last Name'),
          field: 'last_name',
        },


        {
          label: i18n.t('Department'),
          field: 'role',
        },


      ],
      rows: [],
      terminated_employees: [],
      employees: [],
      selected_rows: [],
      searchTerm: '',
      show_terminated: false,
      terminated_label: i18n.t("Show terminated employee"),
      company: JSON.parse(localStorage.getItem('userCompany')),

    }
  },
  watch: {
    show_terminated: {
      handler: function (newValue) {
        console.log('watch show_terminated:' + newValue);
        if (newValue) {
          console.log('terminated_employees', this.terminated_employees)

          this.rows = this.terminated_employees
        } else {
          console.log('employees', this.employees)
          this.rows = this.employees
        }
        console.log(this.rows)
      },
      immediate: true,
    }
  },
  computed: {
    selected_rows_label() {
      let localeMessages = i18n.t('Employees');
      if (this.selected_rows.length === 1) {
        localeMessages = i18n.t('Employee');
      }
      let label = i18n.t('You selected') + ' ' + this.selected_rows.length + ' ' + localeMessages + '. ';
      return label
    },

    print_declaration_label() {
      let localeMessages = i18n.t('Print filled declaration');
      if (this.selected_rows.length === 0) {
        localeMessages = i18n.t('Print empty declaration');
      }

      return localeMessages
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    selectionChanged() {
      console.log("selectionChanged", this.$refs['table'].selectedRows)
      this.selected_rows = this.$refs['table'].selectedRows
    },
    refresh() {
      console.log("refresh")
      this.$refs["list"].showLoading = true


      let company_id = store.state.userCompany.id;
      const employeeService = new EmployeeService(useJwt)

      employeeService.employees(company_id, {}).then(response => {
        this.employees = response.data.data.employees
        this.terminated_employees = response.data.data.terminated_employees

        if (this.show_terminated) {
          this.rows = this.terminated_employees

        } else {
          this.rows = this.employees
        }
        console.log('rows', this.rows)
        this.$refs["list"].showLoading = false

      })

    },

    print_declaration() {
      if (this.selected_rows.length === 0) {
        this.download_white();
      } else {
        this.download_filled();
      }
    },
    download_white() {
      let company_id = store.state.userCompany.id;


      const link = document.createElement("a");
      link.href = environment.baseURL + "/dashboard/downloadEmployerDeclaration/" + company_id + "/0/0"
      link.download = company_id + "_declaration.pdf";
      link.click();
      URL.revokeObjectURL(link.href);

    },

    download_filled() {
      let company_id = store.state.userCompany.id;

      // get all the ids from this.selected_rows
      let ids = [];
      this.selected_rows.forEach(function (item) {
        ids.push(item.id);
      });
      console.log(ids);

      const link = document.createElement("a");
      link.href = environment.baseURL + "/dashboard/downloadEmployerDeclaration/" + company_id + "/1/".concat(ids.join(","))
      link.download =company_id + "_declaration.pdf";
      link.click();
      URL.revokeObjectURL(link.href);

    },


    add_employee() {
      this.$router.push({name: 'employee', params: {id: 'new'}})
    },
    import_bulk() {
      this.$router.push({name: 'import-employees', params: {}})
    },
    edit(id) {
      this.$router.push({name: 'employee', params: {id: id}})
    },
    deleteEmployee(row) {
      console.log('delete')
      let confirmed = false;
      let message = i18n.t('You are deleting the employee') + ": \"" + row.first_name + " " + row.last_name + "\". " + i18n.t("Are you sure?");

      this.$bvModal
          .msgBoxConfirm(message, {
            title: i18n.t('Confirmation Required'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Proceed'),
            cancelTitle: i18n.t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            confirmed = value

            if (confirmed) {
              const employeeService = new EmployeeService(useJwt)
              employeeService.delete_employee(row.id).then(response => {

                if (response.data.status === 'success') {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: i18n.t('Employee deleted'),
                      icon: 'AlertIcon',
                      variant: 'success',
                      text: i18n.t('Employee deleted with success'),
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: i18n.t('Employee not deleted'),
                      icon: 'AlertIcon',
                      variant: 'danger',
                      text: i18n.t(response.data.message),
                    },
                  })
                }


                this.refresh();
              })
            }
          })

    },
    list_documents(id) {
      this.$router.push({name: 'documents', params: {document_section_id: id}})
    },
  },
  mounted() {
    const employeeService = new EmployeeService(useJwt)
    this.$refs["list"].showLoading = true

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });


    let company_id = store.state.userCompany.id;

    this.refresh();
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>
